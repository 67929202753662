const aosMirrorAnchor = 100;

document.addEventListener("DOMContentLoaded", () => {
    document.addEventListener("scroll", () => {
        const indexElements = document.querySelectorAll(".section-steps .step");
        if (indexElements.length !== 0) {
            indexElements.forEach((indexElement, index) => {
                const firstRect = indexElements[0].getBoundingClientRect();
                const rect = indexElement.getBoundingClientRect();
                const prevRect = index > 0 && indexElements[index - 1].getBoundingClientRect();

                if (firstRect.top <= 80) {
                    if (index === indexElements.length - 1 && rect.top < 80) {
                        indexElement.classList.add("aos-unset");
                        indexElement.classList.remove("aos-fix");
                    } else {
                        indexElement.classList.add("aos-fix");
                        indexElement.classList.remove("aos-unset");
                    }
                } else {
                    indexElement.classList.remove("aos-fix");
                    indexElement.classList.remove("aos-unset");
                }
                if ((rect.top >= aosMirrorAnchor && (!prevRect || prevRect.top < aosMirrorAnchor) || (index === indexElements.length - 1 && rect.top < aosMirrorAnchor))) {
                    indexElement.classList.add("aos-animate");
                } else {
                    indexElement.classList.remove("aos-animate");
                }
                
            })
        }
    })
})
