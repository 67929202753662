import "%modules%/header/header";
import "%modules%/navigation/navigation";
import "%modules%/navigation-burger/navigation-burger";
import "%modules%/slider/slider";
import "%modules%/../sections/index/investingInDigital/investingInDigital";
import "%modules%/../sections/index/steps/steps";
import "%modules%/../sections/index/investment/investment";
import "%modules%/../sections/index/strategies/strategies";
import "%modules%/../sections/investment/approaches/approaches";
import "%modules%/../sections/investment/sectors/sectors";
import "%modules%/../sections/contact/contact";
import "%modules%/footer/footer";