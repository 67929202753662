document.addEventListener("DOMContentLoaded", () => {
    const svgElement = document.querySelector("#svg-graph");
    const svgElementWrapper = document.querySelector(".section__graph-wrapper");
    if (svgElement) {
        const scrollHandler = () => {
            if (window.scrollY + window.innerHeight > svgElementWrapper.offsetTop + svgElementWrapper.offsetHeight) {
                svgElement.classList.add("active");
            }
        }
        document.addEventListener("scroll", () => {
            scrollHandler();
        }); 
        scrollHandler();
    }
});