document.addEventListener("DOMContentLoaded", () => {
    let loading = false;


    function handleSubmitMessage() {
        const toast = document.querySelector(".toast"),
              progress = toast.querySelector('.toast__progress'),
              button = toast.querySelector('.toast__close');

        const handleBtnClick = () => toast.classList.remove('active');
        

        let index = 200;

        toast.classList.add('active');

        button.addEventListener('click', handleBtnClick);

        setTimeout(() => {
            const interval = setInterval(() => {
                if (index === 0) {
                    clearInterval(interval);
                    toast.classList.remove('active');
                    button.removeEventListener('click', handleBtnClick);
                }
                index--;
                progress.style.width = `${index / 200 * 100}%`;
            }, 20);
        }, 500);
    }

    function handleError(error) {
        console.error(`error: ${error}`);
    }

    function handlePreventFormSubmit(form) {
        form.addEventListener("submit", event => event.preventDefault());
    }

    function handleSpinnerCreation() {
        const spinner = document.createElement("div");
        spinner.classList.add("spinner");
        return spinner;
    }

    function handleEmailStringCheck(string) {

        return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(string);
    }

    const handleInputsReset = () => {
        const inputs = [...document.querySelectorAll(".input"), ...document.querySelectorAll(".textarea")];
        inputs.forEach(input => input.value = "")
    };

    function validateField(field) {
        if (field.value === "") {
            field.classList.add("input--invalid");
            return false;
        } else if (field.name === "E-mail") {
            if (handleEmailStringCheck(field.value)) {
                field.classList.remove("input--invalid");
                return true;
            } else {
                field.classList.add("input--invalid");
                return false;
            }
        } else {
            field.classList.remove("input--invalid");
            return true;
        }
    }

    function handleFieldsData(form) {
        const fields = [...form.querySelectorAll("input"), ...form.querySelectorAll("textarea")];

        const fieldsDataString = fields.map(field =>  `${field.name}: ${field.value}`).join(", \n");
        return fieldsDataString;
    }

    function toParams(data_js) {
        var form_data = [];
        for ( var key in data_js ) {
            form_data.push(encodeURIComponent(key) + "=" + encodeURIComponent(data_js[key]));
        }

        return form_data.join("&");
    }

    function handleRequest(form, submitBtn) {

        const options = {
            method: "POST",
            headers: {
                "Content-type": "application/x-www-form-urlencoded"
            }
        };

        submitBtn.textContent = "";
        submitBtn.append(handleSpinnerCreation());
        submitBtn.classList.add("button--disabled");

        options.body = toParams({
            "access_token": window.getterOfInfo,
            subject: "Mail from the F1 website",
            text: handleFieldsData(form)
        });
        
        if (!loading) {
            loading = true;
            fetch("https://postmail.invotes.com/send", options)
                .then(() => {
                    handleSubmitMessage();
                    loading = false;
                    document.querySelector(".spinner").remove();
                    handleInputsReset();
                    submitBtn.textContent = "Submit now";
                    submitBtn.classList.remove("button--disabled");
                })
                .catch(error => handleError(error));
        }
    }

    try {
        if (document.getElementById("contact-form")) {
            const form = document.getElementById("contact-form");
            const submitBtn = document.querySelector(".submit");
            const inputs = [...document.querySelectorAll(".input"), ...document.querySelectorAll(".textarea")];
            let isSubmited = false;

            let error = false;

            inputs.forEach(item => item.addEventListener("input", () => {
                validateField(item);
            }));
            

            handlePreventFormSubmit(form);

            submitBtn.addEventListener("click", () => {
                const fieldValidationResult = inputs.every(item => validateField(item));

                fieldValidationResult ? error = false : error = true;

                if (!loading && !error) {
                    if (!isSubmited) {
                        isSubmited = true;
                        handleRequest(form, submitBtn);
                    } else if (isSubmited) {
                        inputs.forEach(input => input.parentElement.style.display = "block");
                        isSubmited = false;
                    }
                }
            });   

        }
    } catch(e) {
        console.log();
    }
});
