document.addEventListener('DOMContentLoaded', () => {
    try {
        if (document.querySelectorAll('.slide')) {
            const slides = document.querySelectorAll('.slide');
            const slidesParent = document.querySelector('.slider__wrapper');
            
            const handleOpen = (targetSlideIndex) => {
                slides.forEach(item => {
                    item.classList.add('slide--closed');
                })
                
                slides[targetSlideIndex - 1].classList.remove('slide--closed');
            };
    
            
    
            slidesParent.addEventListener('click', (e) => {
                const target = e.target.closest('.slide');
                if (target.classList.contains('slide--closed')) {
                    handleOpen(target.dataset.slideindex)
                }
            });
        
        }
    } catch (e) {}
    
});