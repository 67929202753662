document.addEventListener("DOMContentLoaded", () => {
    const currentPath = window.location.pathname;
    const navListItems = document.querySelectorAll(".navigation__item");

    function changeActiveNavItem(path) {
        navListItems.forEach((item) => {
            const hrefContent = item.getAttribute("href");
            if (path.split("/")[1] === hrefContent.split("/")[1]) {
                item.classList.add("active");
            } else {
                item.classList.remove("active");
            }
        });
    }
    changeActiveNavItem(currentPath);
    

    
    setTimeout(() => {
        [...navListItems].forEach(item => {
            if (item.textContent.trim() === 'The F1 Philosophy') {
                item.setAttribute('href', '/#investing-in-digital')
            }
        })
    }, 100);
    
    

});