const scrollContainer = document.querySelector('.section-strategies .section__right-side');
const gradientTop = document.querySelector('.section-strategies .section__gradient--top');
const gradientBottom = document.querySelector('.section-strategies .section__gradient--bottom');
const notVisibleModifier = "section__gradient--not-visible";

const scrollHandler = () => {
    const scrollAtTheTop = scrollContainer.scrollTop === 0;
    const scrollAtTheBottom = scrollContainer.scrollHeight - Math.round(scrollContainer.scrollTop) === scrollContainer.clientHeight;

    if (scrollAtTheTop) {
        gradientTop.classList.add(notVisibleModifier);
    } else {
        gradientTop.classList.remove(notVisibleModifier)
    }

    if (scrollAtTheBottom) {
        gradientBottom.classList.add(notVisibleModifier);
    } else {
        gradientBottom.classList.remove(notVisibleModifier)
    }
}

if (scrollContainer) {
    scrollContainer.addEventListener("scroll", scrollHandler);
    window.addEventListener("load", scrollHandler);
    window.addEventListener("resize", scrollHandler);
}
