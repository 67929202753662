function handleModeChange(mode) {
    if (!mode) {
        console.error("No mode given, please specify prod / test");
        return;
    }
    if (mode === "prod") {
        window.getterOfInfo = "lbkurie947a4pz1mjpmm2f5s";
    }
    if (mode === "test") {
        window.getterOfInfo = "og36w5iv7u3v0fbabbjk6m7t";
    }
}


handleModeChange("prod");
window.handleModeChange = handleModeChange;