document.addEventListener("DOMContentLoaded", () => {
    const burgerBtnOpen = document.querySelector(".burger__btn");
    const list = document.querySelector(".navigation-burger__list");
    const header = document.querySelector('.header');
    const additionalWrapper = document.querySelector('.additional__wrapper');
    

    burgerBtnOpen.addEventListener("click", (e) => {
        header.classList.toggle("header--active");
        if (document.body.style.overflow === "hidden") {
            additionalWrapper.classList.remove('show');
            header.classList.remove('safari-fix')
            document.body.style.overflow = "";
        } else {
            document.body.style.overflow = "hidden";
            header.classList.add('safari-fix')
            setTimeout(() => {
                additionalWrapper.classList.add('show');
            }, 400);
        }
    });
    list.addEventListener('click', (e) => {
        if (e.target.closest('.navigation__item') && e.target.textContent.trim() === 'The F1 Philosophy') {
            burgerBtnOpen.click();
            setTimeout(() => {
                history.pushState("", document.title, window.location.pathname + window.location.search)
            }, 50);
        }
    })
});