document.addEventListener("DOMContentLoaded", () => {
    if (document.querySelector('.accordion')) {
        const accordions = document.querySelectorAll('.accordion');
        const accordionParent = document.querySelector('.section-investment .section__right-side');
        


        accordionParent.addEventListener('click', (e) => {
            if (window.innerWidth > 1170) {
                const target = e.target.closest('.accordion');
                [...accordions].filter(item => {
                    if (item === target) {
                        target.classList.toggle('accordion--closed');
                    } else {
                        return item;
                    }
                }).forEach(item => item.classList.add('accordion--closed'))
            }
        })
    }
});